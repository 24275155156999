import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/material_warehouses', data);
  },
  get(id) {
    return api.fetch('/material_warehouses/' + id);
  },
  list(params) {
    return api.fetch('/material_warehouses', { params });
  },
  update(data) {
    return api.patch('/material_warehouses', data);
  },
  delete(id) {
    return api.remove('/material_warehouses', id);
  },
};
